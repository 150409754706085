<template>
  <div id="mainContainer" class="flex relative">
    <!-- stats when not in production -->
    <div v-if="dev == 1 && isNotProduction" class="absolute w-full">
      <div class="z-50 relative py-8 px-8 shadow-2xl" style="background: #f1f1f1">
        <h1 class="text-dark text-2xl">JWT Token:</h1>
        <p class="text-dark break-all cursor-pointer" @click="copy">{{ getToken() }}</p>
        <a :href="'/api/coaf/diag/all/' + getToken()" target="_blank">
          <NextButton class="mt-5" label="Go to output page"> </NextButton>
        </a>
      </div>
      <div class="fixed inset-0 backdrop-filter backdrop-blur-lg z-10"></div>
    </div>
    <SideBar
      v-if="isSidebarVisible"
      id="leftSideBar"
      class="hidden lg:flex h-screen dropRightShadow text-center sticky top-0"
      :logoUrl="logoUrlSide"
      :text="flowData?.flow?.sideBarDescription"
      :logoPosition="logoPosition"
    />
    <div v-if="routeDefined" class="w-full">
      <chooseLanguage
        v-if="flowMeta.language"
        :selectedLang="selectedLang"
        :languages="languages"
        :key="$route.params.pageID"
        class="absolute right-2 lg:right-4 mt-4 md:mt-6 lg:mt-[0.5rem]"
      />
      <div id="topBar" class="topBar w-full" :class="[showTopBarOverride ? '' : 'lg:hidden']">
        <TopBar :logoUrl="logoUrl" />
      </div>
      <div
        v-if="flowMeta.serviceMessage"
        class="px-3 md:px-0 flex justify-center items-center showAnim bg-[#ffebc1] border-b border-[#d1b67b]"
      >
        <h2 class="pl-0 md:pl-3 p-3 text-center text-lg">
          {{ flowMeta.serviceMessage }}
        </h2>
      </div>
      <Suspense>
        <FlowWrapper />
      </Suspense>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
    <div
      v-if="dev == 1 && isNotProduction"
      class="absolute bottom-0 border border-t w-full p-8 z-50 shadow-2xl"
      style="background: #f1f1f1"
    >
      <h1 class="text-dark text-2xl">User details:</h1>

      <div
        v-for="meta of Object.entries(flowMeta)"
        :key="meta[0]"
        class="grid grid-flow-col grid-cols-12 auto-cols-max max-w-xl"
      >
        <span class="break-all col-span-3 border-b">{{ meta[0] }}:</span>
        <span
          v-if="meta[0] === 'companyId'"
          class="break-all col-span-9 border-b font-bold cursor-pointer"
          @click="copy"
          >{{ meta[1] }}</span
        >
        <span v-else class="break-all col-span-9 border-b">{{ meta[1] }}</span>
      </div>
    </div>
  </div>

  <FadeTransition :duration="150">
    <modal
      v-if="modalState.isVisible"
      :duration="100"
      :closeBtnIsVisible="modalState.showCloseIcon"
      :closeOnClickOutside="modalState.modal"
      :hideFunction="() => $store.dispatch('ui/hideModal')"
      specificWidth="25rem"
      id="modalOffset"
    >
      <slot>
        <component :is="modalState.modalType" />
      </slot>
    </modal>
  </FadeTransition>
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent } from 'vue';
import FlowWrapper from './views/FlowWrapper.vue';
import { TopBar, SideBar } from '@pensure/ui-components';
import chooseLanguage from '../coaf/components/chooseLanguage.vue';

export default defineComponent({
  components: {
    FlowWrapper,
    TopBar,
    SideBar,
    chooseLanguage,
    resetModal: defineAsyncComponent(() => import('./components/resetFlowModal.vue')),
    abortModal: defineAsyncComponent(() => import('./components/abortFlowModal.vue')),
    errorModal: defineAsyncComponent(() => import('./components/errorModal.vue')),
    jsonContentModal: defineAsyncComponent(() => import('./components/jsonContentModal.vue')),
    pensionProvidersModal: defineAsyncComponent(() => import('./components/pensionProvidersModal.vue')),
  },
});
</script>

<script lang="ts" setup>
import { ref, Ref, watch, computed } from 'vue';

import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useTheme } from '../controller/theme';
import { flowRepository } from '@coaf-library/IO/flow-web-repository';
import { getToken, toQueryObject } from '../lib/helpers';

const routeDefined = ref(false);
const route = useRoute();
const store = useStore();
const router = useRouter();

const flowData: Ref<Record<string, unknown>> = computed(() => store.getters['flowGraph/GET_GRAPH']);
const flowMeta: Ref<Record<string, unknown>> = computed(() => store.getters['flowGraph/GET_METADATA']);
const logoUrl = computed(() => store.getters['links/GET_LINKS']['topbar-logo']);
const logoUrlSide = computed(() => store.getters['links/GET_LINKS']['side-bar-logo']);
const isSidebarVisible = computed(() => store.getters['ui/GET_SIDEBAR_STATE'].isVisible);
const showTopBarOverride = computed(() => store.getters['ui/GET_TOPBAR_STATE'].showOverride);
const logoPosition = computed(() => store.getters['ui/GET_LOGOOPTIONS_STATE'].alignment);
const isNotProduction = import.meta.env.DEV;
const modalState = computed(() => store.getters['ui/GET_MODAL_STATE']);

async function setupFlow() {
  const hrefArr = window.location.href.split('/');
  hrefArr.splice(0, 3);
  let appName = hrefArr.length > 1 ? hrefArr[0] : hrefArr.find((x) => x.startsWith('?') === false);
  const lastElement = hrefArr[hrefArr.length - 1].split('?');
  const queryString = lastElement.length === 2 ? lastElement[1] : '';

  if (appName === '404') {
    // app = createApp(NotFound);
  } else if (appName === '' || appName === undefined) {
    const res = await flowRepository.startSubmissionCommand({}, toQueryObject(queryString));

    appName = res.appName;

    window.location.replace([appName, res.flow.name, res.flow.startNodeId].join('/') + `?${queryString}`);
  }
  const { ok } = await useTheme(appName);
  if (ok === false) {
    await router.push({ name: 'NotFound' });
  }
}
setupFlow();

const languages = [
  { label: 'Danish', locale: 'DA', url: '/svg/DK.svg' },
  { label: 'English', locale: 'EN', url: '/svg/GB.svg' },
];

const selectedLang = computed(() => {
  const preselected = languages.find((el) => el.locale.toLowerCase() === flowMeta.value?.language?.toLowerCase());
  return !preselected ? languages[0] : preselected;
});

function copy(e) {
  const range = document.createRange();
  range.selectNodeContents(e.target);
  const sel = window.getSelection();
  sel?.removeAllRanges();
  sel?.addRange(range);

  document.execCommand('copy');
}

watch(
  () => route.params.flowID,
  () => (routeDefined.value = true)
);

const { currentProgress, allValues, dev } = godMode();

// onErrorCaptured((e, target) => {
//   const error = {
//     errorTicket: null,
//     errorUserMessage: null,
//     errorSystemMessage: e,
//   };
//   //store.commit('ui/SET_MODAL_STATE', { isVisible: true, modalType: 'errorModal', dynamicText: error });
// });

function useDev() {
  window['dev'] = ref(0);
  return computed(() => {
    const dev = window['dev'];

    return dev.value;
  });
}

//function godMode(flowService: unknown, route: unknown) {
function godMode() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dev = useDev();
  return { currentProgress: null, allValues: null, dev };
}
</script>
<style lang="scss" scoped>
.dropRightShadow {
  box-shadow: 9px 0px 13px 0px var(--side-bar-shadow-color);
}
</style>
