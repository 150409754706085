import sleep from '../../coaf-library/helpers/sleep';

import { debug } from './logger/logger-decorator';
interface Trap {
  keyCode: number;
  key: string;
  fn: (evt: Event, ...args: any) => any;
}
export class KeyboardTrap {
  traps: Trap[] = [];
  asyncTraps: Trap[] = [];
  registerTrap = (trap: any) => {
    this.traps.push(trap);
  };

  registerAsyncTrap = (trap: any) => {
    this.asyncTraps.push(trap);
  };

  // @debug
  trapKeyboard = (evt: any) => {
    /**
     *  Modifier keys:
     *  - evt.ctrlKey
     *  - evt.metaKey
     *  - evt.shiftKey
     *
     */
    /**
     * keyCode is best supported across browsers, but "key" would be better to move to as it is more readable
     * E.g. Enter vs 13
     */
    const matchedTraps = this.traps.filter((trap) => trap.keyCode === evt.keyCode);

    // if no matched traps, just let the browser handle the input
    if (matchedTraps.length === 0) {
      return true;
    }

    this.runAsyncTraps(evt);

    return this.runSyncTraps(evt);
  };

  runAsyncTraps(evt: any) {
    const matchedTraps = this.asyncTraps.filter((trap) => trap.keyCode === evt.keyCode);
    matchedTraps.forEach((trap: Trap) => this.callAsync(trap.fn, evt));
  }

  runSyncTraps(evt: any) {
    const matchedTraps = this.traps.filter((trap) => trap.keyCode === evt.keyCode);

    let shouldKeyBePassedThrough = true;
    matchedTraps.forEach((trap: Trap) => {
      const res = this.callSync(trap.fn, evt);
      if (res === false) {
        shouldKeyBePassedThrough = false;
      }
    });
    return shouldKeyBePassedThrough;
  }

  callAsync = async (fn: (...args: any) => any, params: any): Promise<any> => {
    await sleep(0);
    return fn(params);
  };

  callSync = (fn: (...args: any) => any, params: any): boolean => {
    return fn(params);
  };
}
const godModeTrap = (evt: any) => {
  if (evt.metaKey || evt.ctrlKey) {
    enableGodMode();
    return false;
  }
  return true;
};

function enableGodMode() {
  const val = (window as any).dev.value;
  //console.log('dev', (window as any).dev.value);
  (window as any).dev.value = val === 1 ? 0 : 1;
}

export const keyboard = new KeyboardTrap();

keyboard.registerTrap({ keyCode: 75, fn: godModeTrap });
keyboard.registerTrap({
  keyCode: 13,
  fn: () => {
    //console.log('SLAVE');
  },
});
