import { createRouter, createWebHistory, RouteLocationNormalized } from 'vue-router';
import { routes } from './routes';
import { analyticsLogger } from '@coaf-library/helpers/AnalyticsService';

const router = createRouter({
  history: createWebHistory((import.meta as any).env.BASE_URL),
  routes,
});

router.beforeEach(() => analyticsLogger.sendEvent('$pageview'));

export default router;
