import { mergeDeepLeft, mergeDeepRight } from 'ramda';

type ModalType = 'resetModal' | 'abortModal' | 'errorModal' | 'jsonContentModal' | 'pensionProvidersModal';

import { Commit } from 'vuex';

/* eslint-disable @typescript-eslint/no-unused-vars */
export interface Modal {
  isVisible: boolean;
  modalType: null | ModalType;
  jsonModalId: null | string;
  dynamicText: null | string;
  customBtnHandling: null | Record<string, string>;
  width: string | null;
  modal: boolean | null;
  showCloseIcon: boolean;
}

export interface Sidebar {
  isVisible: boolean;
}

export interface Topbar {
  showOverride: boolean;
}

export interface ForwardButton {
  isDataLoading: boolean;
}
export interface LogoOptions {
  alignment: null | string;
}

export interface UIState {
  ForwardButton: ForwardButton;
  modal: Modal;
  sidebar: Sidebar;
  topbar: Topbar;
  logoOptions: LogoOptions;
}

const state = (): UIState => ({
  ForwardButton: {
    isDataLoading: false,
  },
  modal: {
    isVisible: false,
    modalType: null,
    jsonModalId: null,
    dynamicText: null,
    customBtnHandling: null,

    width: null,
    modal: false,
    showCloseIcon: false,
  },
  sidebar: {
    isVisible: false,
  },
  topbar: {
    showOverride: false,
  },
  logoOptions: {
    alignment: null,
  },
});

const getters = {
  GET_UI_STATE: (state: UIState): UIState => {
    return state;
  },
  GET_BUTTON_STATE(state: UIState): ForwardButton {
    return state.ForwardButton;
  },
  GET_MODAL_STATE(state: UIState): Modal {
    return state.modal;
  },
  GET_SIDEBAR_STATE(state: UIState): Sidebar {
    return state.sidebar;
  },
  GET_TOPBAR_STATE(state: UIState): Topbar {
    return state.topbar;
  },
  GET_LOGOOPTIONS_STATE(state: UIState): LogoOptions {
    return state.logoOptions;
  },
};

const mutations = {
  SET_UI_STATE(state: UIState, newPropertyValue: Record<string, unknown>): void {
    state = Object.assign({}, state, newPropertyValue);
  },
  SET_MODAL_STATE(state: UIState, newPropertyValue: Modal): void {
    const mergedObj = mergeDeepRight(state.modal, newPropertyValue);
    state.modal = mergedObj;
  },
  SET_BUTTON_STATE(state: UIState, newPropertyValue: boolean): void {
    state.ForwardButton.isDataLoading = newPropertyValue;
  },
  SET_SIDEBAR_STATE(state: UIState, newPropertyValue: boolean): void {
    state.sidebar.isVisible = newPropertyValue;
  },
  SET_TOPBAR_STATE(state: UIState, newPropertyValue: boolean): void {
    state.topbar.showOverride = newPropertyValue;
  },
  SET_LOGOOPTIONS_STATE(state: UIState, newPropertyValue: string | null): void {
    state.logoOptions.alignment = newPropertyValue;
  },
};

const actions = {
  toggleButton({ commit, state }: { commit: Commit; state: UIState }): void {
    commit('SET_BUTTON_STATE', !state.ForwardButton.isDataLoading);
  },

  toggleSidebar({ commit, state }: { commit: Commit; state: UIState }): void {
    commit('SET_SIDEBAR_STATE', !state.sidebar.isVisible);
  },
  toggleTopbar({ commit, state }: { commit: Commit; state: UIState }): void {
    commit('SET_SIDEBAR_STATE', !state.topbar.showOverride);
  },
  hideModal({ commit }: unknown): void {
    const resetModalState = {
      isVisible: false,
      modalType: null,
      jsonModalId: null,
      dynamicText: null,
      customBtnHandling: null,
      width: null,
      modal: false,
      showCloseIcon: false,
    };

    commit('SET_MODAL_STATE', resetModalState);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
