<template>
  <div id="flowWrapper" class="flex relative">
    <WizardApp v-if="app === 'Wizard' && flowService" class="w-full" />
    <ConversationalApp v-else-if="app === 'Conversational' && flowService" />
    <LoadingSpinner v-else :size="70" class="mt-10 w-full justify-center" />
  </div>
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent } from 'vue';
import { getToken } from '@/lib/helpers';

export default defineComponent({
  components: {
    WizardApp: defineAsyncComponent(() => import('../../wizard-flow/views/wizard/WizardFlow.vue')),
    ConversationalApp: defineAsyncComponent(() => import('../../conversational-flow/ConversationalApp.vue')),
  },
});
</script>

<script lang="ts" setup>
import { computed, watch, ref, Ref, provide } from 'vue';
import { Router, useRoute, useRouter } from 'vue-router';
import { WizardFlow, wizardFlowFactory } from '@/wizard-flow/helpers/wizard-setup';
import { Store, useStore } from 'vuex';
import { bootstrapApiSocket } from '@/bootstrap';
import { State } from '../store';
import { SignalR } from '@coaf-library/IO/signalRWrapper';
import { LoadingSpinner } from '@pensure/ui-components';

const route = useRoute();
const router = useRouter();
const store = useStore();

const appID = route.params.application;
const flowID = route.params.flowID;
const meta = computed(() => store.getters['flowGraph/GET_METADATA']);

if (typeof appID !== 'string') {
  console.error(route.path);
  throw Error('FlowWrapper defined without appID');
}

// const switchApp = () => {
//   app.value = app.value == 'Wizard' ? 'Conversational' : 'Wizard';
// };

function setFaviconAndTabName(url: string, name: string) {
  document.title = name;
  (document.querySelector("link[rel*='icon']") as HTMLLinkElement).href = url;
}
const apiSocket: Ref<SignalR | undefined> = ref(undefined);

watch(
  () => route.params.flowID as string,
  async (newVal) => {
    if (apiSocket.value === undefined) return;

    const { newWFZ } = await update(route.params.application as string, newVal, store, router, apiSocket.value);
    wizardFlowService.value = newWFZ;
  }
);

await store.dispatch('flowGraph/createGraph', {
  appID,
  flowID,
  nodeID: route.params.pageID,
  router,
});

const flowService = computed(() => store.getters['flowGraph/GET_GRAPH']);
const favIconUrl = `/images/${meta.value?.companyId}favicon.png`;
setFaviconAndTabName(favIconUrl, flowService.value.flow?.title);

if (route.params.pageID === '') {
  router.push({
    name: 'WizardFlow',
    params: {
      application: appID,
      flowID: flowService.value.flowName,
      pageID: flowService.value.initialState?.nodeId,
    },
    query: Object.assign({}, route.query),
  });
}

apiSocket.value = await bootstrapApiSocket('', getToken());
const wizardFlowService = wizardFlowFactory(flowService, router, apiSocket.value, store) as unknown as Ref<WizardFlow>;
const app = computed(() => flowService.value.flow?.flowType);

async function update(newApp: string, newFlow: string, store: Store<State>, router: Router, apiSocket: SignalR) {
  const newWizardFlowService = wizardFlowFactory(flowService.value, router, apiSocket, store);
  return { newWFZ: newWizardFlowService };
}

provide('flow', wizardFlowService);
provide('apiSocket', apiSocket.value);
</script>
