import { Vue } from '@sentry/integrations';
import { flowRepository } from '../../coaf-library/IO/flow-web-repository';
import variablesLocal from '../variables.json';
import { store } from './../coaf/store';

export interface Variables {
  styles: Record<string, string>;
  images: Record<string, string>;
  logo: {
    alignment: 'top' | 'bottom' | null;
  };
}

export function setTheme(variables: Variables): void {
  const root = document.documentElement;
  if (variables.logo) store.commit('ui/SET_LOGOOPTIONS_STATE', variables.logo.alignment);
  for (const key in variables.styles) {
    root.style.setProperty(`--${key}`, variables.styles[key]);
  }
  for (const key in variables.images) {
    store.commit('links/SET_LINKS', { [key]: variables.images[key] });
  }
}

export async function fetchTheme(companyName?: string): Promise<Variables> {
  if (companyName) {
    return flowRepository.getStyles(companyName);
  } else {
    return variablesLocal;
  }
}

export async function useTheme(companyName?: string): Promise<{
  ok: boolean;
  err: null;
}> {
  try {
    const variables = await fetchTheme(companyName);
    setTheme(variables);
    return { ok: true, err: null };
  } catch (error) {
    return { ok: false, err: error };
  }
}
