interface CustomScriptTag {
  async?: boolean;
  defer?: boolean;
  id?: string;
  src?: string;
  text?: string;
  type?: string;
}

export default function injectScripts(parent: HTMLElement, list: CustomScriptTag[]) {
  list.forEach((el: any) => {
    const script = document.createElement('script');
    Object.assign(script, { ...el });
    parent.appendChild(script);
  });
}
