import { AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from 'axios';
//import { MockAPI } from './db';
import { API } from './api';
import { LocationQuery } from 'vue-router';
import { APIError } from './api/models/api-errors';
import { store } from '../../src/coaf/store/index';
import { DevFlow } from './db';
import { APIErrorResponse } from './api/models/api-response';

export interface FlowRepository {
  getSubmission(submissionID: string): Promise<any>;
  startSubmissionCommand(
    payload?: { appID: string; flowID: string; nodeID: string },
    params?: LocationQuery
  ): Promise<any>;
  update(id: string, payload: Record<string, any>): Promise<any>;
  listFlows(): Promise<DevFlow | unknown>;
  getStyles(flowName: string): Promise<any>;
  abortFlow(): Promise<any>;
  fileUploadApi(url: string, data: any, configuration?: AxiosRequestConfig): Promise<any>;
}
export interface PensureAxios {
  getData(url: string): Promise<any>;
  startSubmissionCommand(payload?: { appID: string }, params?: LocationQuery): Promise<any>;
  update(id: string, payload: Record<string, any>): Promise<any>;
  listFlows(): Promise<DevFlow | unknown>;
  getStyles(flowName: string): Promise<any>;
  abortFlow(): Promise<any>;
  fileUploadApi(url: string, data: any, configuration?: AxiosRequestConfig): Promise<any>;
}

const flowRepositoryFactory =
  (api: API) =>
  (resource: string): FlowRepository => ({
    async getSubmission(submissionID: string) {
      const res = await api.getData(`${resource}/${submissionID}`);
      if (!res?.success) {
        setupError(res);
        return false;
      }
      return res.data;
    },

    async startSubmissionCommand(payload: { appID: string; flowID: string; nodeID: string }, params: LocationQuery) {
      const url = [resource, payload.appID, payload.flowID, payload.nodeID]
        .filter((x) => x != undefined)
        .join('/')
        .replace(/([^:]\/)\/+/g, '$1');
      const res = await api.getData(url, params);
      if (!res?.success) {
        setupError(res);
        return false;
      }
      return res.data;
    },

    async getStyles(flowName: string) {
      const res = await api.getData(`${resource}/styles/${flowName}`);
      if (!res?.success) {
        setupError(res);
        return false;
      }
      return res.data;
    },

    async abortFlow() {
      const res = await api.postData(`${resource}/flow/abort`, {});
      return res?.data;
    },

    async fileUploadApi(url: string, data: Record<string, string>, configuration?: AxiosRequestConfig) {
      const res = await api.postData(url, data, configuration);
      if (!res.data || !(res as any).success) {
        return res;
      }
      return res.data;
    },

    async update(id: string, payload: Record<string, string>) {
      const res = (await api.postData(`${resource}/${id}`, payload)).data;
      if (!res?.success) {
        if (res.errorSystemMessage) {
          store.commit('ui/SET_MODAL_STATE', { isVisible: true, modalType: 'errorModal', dynamicText: res });
          //throw new APIError('update failed ', { errorSystemMessage: res.errorSystemMessage });
        } else if (res.errors?.length) {
          res.errors.forEach((x) => {
            throw new APIError('update failed ', x);
          });
        }
      }
      return res.data;
    },

    async listFlows() {
      const res = await api.getData(`${resource}/list`);
      // if (res.hasData === false) {
      //   return false;
      // }
      // const data = res.data as Record<string, Record<string, unknown>>;
      // return data['flows'];
      if (!res.data || !res.success) {
        return res;
      }
      return res.data;
    },
  });
export default flowRepositoryFactory;

function setupError(res: APIErrorResponse) {
  store.commit('ui/SET_MODAL_STATE', { isVisible: true, modalType: 'errorModal', dynamicText: res });
}

const pensureAxios = (axios: AxiosInstance) => new API(axios);

const dev = window.location.host.startsWith('localhost');

const pathSep = window.location.origin.startsWith('/') ? '' : '/';
const url = dev ? 'http://localhost:5000/api/coaf' : window.location.origin + pathSep + 'api/coaf';
export const flowRepository = flowRepositoryFactory(pensureAxios(axios))(url);

// const createFullGetFlowURL = (origin: string, pathname: string) => {
//   const paths = pathname;
//   const fullURL = `${origin}/api/coaf/${paths}`;
//   return fullURL;
// };
