import { store } from '@/coaf/store/index';
import { getToken, setToken } from '@/lib/helpers';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken, HeadersDefaults } from 'axios';
import { LocationQuery } from 'vue-router';
import { APIError, AuthorizationError } from './models/api-errors';
import { APIErrorResponse, APIResponse } from './models/api-response';

interface CancellableResponse {
  data: APIResponse;
  source: unknown;
}

interface CustomHeader extends HeadersDefaults {
  Authorization: string;
}

export class API {
  constructor(private axios: AxiosInstance) {}

  async getData(url: string, params?: LocationQuery): Promise<APIResponse | undefined> {
    const token = getToken();
    const config: Record<string, unknown> = {
      params,
    };

    (this.axios.defaults.headers as CustomHeader).Authorization = `Bearer ${token}`;

    try {
      const response = await this.axios.get(url, config);

      if (response.status !== 200) {
        throw this.createAPIErrorResponse(response);
      }

      const res = response.data;

      if (res.success === false) {
        store.commit('ui/SET_MODAL_STATE', {
          isVisible: true,
          modalType: 'errorModal',
          dynamicText: { errorUserMessage: res },
        });
      }

      if (res.data?.token) setToken(res.data.token);

      return res;
    } catch (error) {
      store.commit('ui/SET_MODAL_STATE', {
        isVisible: true,
        modalType: 'errorModal',
        dynamicText: { errorUserMessage: error },
      });
    }
  }

  async postData(
    url: string,
    payload: Record<string, string>,
    configuration?: AxiosRequestConfig
  ): Promise<CancellableResponse | undefined> {
    const token = getToken();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const config: Record<string, any> = {};
    let source = null;

    if (configuration) {
      if (configuration?.cancelToken) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        source = (this.axios as any).CancelToken.source();
        const cancelToken = source.token as CancelToken;
        configuration.cancelToken = cancelToken;
      }
      Object.assign(config, configuration);
    }
    if (token) {
      if (Object.prototype.hasOwnProperty.call(config, 'headers') == false) {
        config.headers = {};
      }
      config.headers.Authorization = `Bearer ${token}`;
    }

    try {
      const response = await this.axios.post(url, payload, config);

      if (response.status !== 200) {
        store.commit('ui/SET_MODAL_STATE', {
          isVisible: true,
          modalType: 'errorModal',
          dynamicText: { errorUserMessage: response.status },
        });
      }

      const data = response.data;

      if (data.success === false) return { data, source };
      if (response.data?.token) setToken(response.data.token);

      return { data, source };
    } catch (error) {
      store.commit('ui/SET_MODAL_STATE', {
        isVisible: true,
        modalType: 'errorModal',
        dynamicText: { errorUserMessage: error },
      });
    }
  }

  private createAPIErrorResponse(res: AxiosResponse) {
    if (res.status === 401) {
      return new AuthorizationError(`Unauthorized call`);
    }
    if (res.status === 500) {
      return new APIError(`Failed API Call`, {
        errorMessage: [res.statusText],
        errorTicket: undefined,
        errorTrace: undefined,
        errorType: res.status.toString(),
        errorUserMessage: 'Internal system error',
      });
    }
    return new APIError(`Failed API Call`, {
      errorMessage: [res.statusText],
      errorTicket: undefined,
      errorTrace: undefined,
      errorType: res.status.toString(),
      errorUserMessage: 'Ukendt fejl',
    });
  }

  private createKnownAPIErrorResponse(res: APIErrorResponse) {
    return new APIError(`Bad Response`, {
      errorMessage: res.errorSystemMessage,
      errorTicket: res.errorTicket,
      errorTrace: undefined,
      errorType: '200',
      errorUserMessage: res.errorUserMessage,
    });
  }
}
