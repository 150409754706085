import * as Sentry from '@sentry/browser';
import { ExtraErrorData, Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { keyedString } from './helperTypes';

export interface ErrorService {
  init: (options: keyedString) => void;
  error: (error: Error, data?: keyedString) => void;
  log: (name: string, data?: keyedString) => void;
  warn: (name: string, data?: keyedString) => void;
  identify: (uid: string, data?: keyedString) => void;
  anonIdentify: (data: keyedString) => void;
}
export class ErrorLogger {
  constructor(private loggerService: ErrorService) {}
  withConsole = true;
  init(options: any): void {
    if (options?.overrideConsole) {
      this.overrideConsole();
    }
    if (this.withConsole) {
      console.log('[LOGGER] INIT', options);
    }
    this.loggerService.init(options);
  }

  private overrideConsole(): void {
    this.withConsole = false;
    window['console']['log'] = (msg: any, data: any) => this.log(msg, data);
    window['console']['warn'] = (msg: any, data: any) => this.warn(msg, data);
    window['console']['error'] = (msg: any, data: any) => this.error(msg, data);
  }

  error(error: Error, data?: keyedString): void {
    // Sentry.captureException(error, data);
    // name: string, data?: keyedString
    if (this.withConsole) {
      console.error('[LOGGER] ERROR', error);
    }
    this.loggerService.error(error, data);
    // this.loggerService.error(error.name, error.message);
  }
  log(name: string, data?: keyedString): void {
    if (this.withConsole) {
      console.log('[LOGGER] LOG', name, data);
    }
    this.loggerService.log(name, data);
  }
  warn(name: string, data?: keyedString): void {
    if (this.withConsole) {
      console.warn('[LOGGER] WARN', name, data);
    }
    this.loggerService.warn(name, data);
    // this.loggerService.warn(name, data);
  }
  identify(uid: string, data?: keyedString): void {
    if (this.withConsole) {
      console.log('[LOGGER] IDENTIFY', uid, data);
    }
    this.loggerService.identify(uid, data);
  }
  anonIdentify(data: keyedString): void {
    if (this.withConsole) {
      console.log('[LOGGER] IDENTIFY', data);
    }
    this.loggerService.anonIdentify(data);
  }
  // startTransaction(name: string) {
  //   return this.loggerService.startTransaction({name})
  // }
  // finishTransaction(transaction) {
  //   return transaction.finish()
  // }
}

export const createErrorLoggerFromSentry = (Sentry: any) => (VueInstance: any): ErrorService => {
  let reportDialogOpen = false;
  return {
    init: (options: keyedString) => {
      Sentry.init({
        ...options,
        integrations: [
          new VueIntegration({
            Vue: VueInstance,
            attachProps: true,
            tracing: true,
            tracingOptions: { trackComponents: true },
          }),
          new Integrations.BrowserTracing({
            tracingOrigins: [/.*.pensure.dk/, /.*.pensure.test/],
          }),
          new ExtraErrorData(),
        ],
        tracingOptions: {
          trackComponents: true,
        },
        autoSessionTracking: true,
        tracesSampleRate: 0.95,
        beforeSend(event: any, hint: any) {
          // Check if it is an exception, and if so, show the report dialog
          if (event.exception && reportDialogOpen == false) {
            reportDialogOpen = true;
            Sentry.showReportDialog({
              eventId: event.event_id,
              title: 'Det ser ud til at du har mødt en fejl',
              subtitle: 'Vi er blevet underrettet',
              subtitle2: 'Hvis du vil hjælpe, så fortæl os hvad der skete',
              labelName: 'Navn',
              labelComments: 'Hvad skete der?',
              labelClose: 'Luk',
              labelSubmit: 'Send',
              errorFormEntry: 'Nogle felter er ikke udfyldt korrekt. Ret dem og prøv igen.',
              successMessage: 'Din feedback er blevet sendt. Tak!',
              onLoad: () => {
                const handleClickEvent = () => {
                  reportDialogOpen = false;
                  document
                    .querySelector('.sentry-error-embed .close')
                    ?.removeEventListener('click', handleClickEvent, true);
                };
                document.querySelector('.sentry-error-embed .close')?.addEventListener('click', handleClickEvent, true);
              },
            });
          }
          return event;
        },
      });
      return;
    },
    error: (error: Error, data?: keyedString) => {
      Sentry.captureException(error, { level: Sentry.Severity.Error, extra: data });
      return;
    },
    log: (name: string, data?: keyedString) => {
      // Sentry.captureMessage(name, { level: Sentry.Severity.Info, extra: data });
      return;
    },
    warn: (name: string, data?: keyedString) => {
      // Sentry.captureMessage(name, { level: Sentry.Severity.Warning, extra: data });
      return;
    },
    identify: (uid: string, data?: keyedString) => {
      Sentry.configureScope(function (scope: any) {
        scope.setUser({ id: uid, ...data });
      });
      return;
    },
    anonIdentify: (data: keyedString) => {
      Sentry.configureScope(function (scope: any) {
        scope.setUser(data);
      });
      return;
    },
  };
};
// const logger = LogRocket as any;
// const logger = Sentry as any;
export const logger = createErrorLoggerFromSentry(Sentry);
