export interface APIErrorType {
  errorType?: string;
  errorUserMessage?: string;
  errorMessage?: string[] | APIErrorType[] | string;
  errorTrace?: string;
  errorTicket?: string;
  errorSystemMessage?: string;
}
export class APIError extends Error {
  data: APIErrorType;
  constructor(message: string, data: APIErrorType) {
    super(message);
    this.data = data;
    Object.setPrototypeOf(this, APIError.prototype);
  }

  getData(): APIErrorType {
    return this.data;
  }
}

export class AuthorizationError extends Error {
  data: APIErrorType | undefined;
  constructor(message: string, data?: APIErrorType) {
    super(message);
    this.data = data;
    Object.setPrototypeOf(this, AuthorizationError.prototype);
  }
}
