import { createStore, createLogger } from 'vuex';
import ui, { UIState } from './modules/ui';
import links, { Links } from './modules/links';
import flowGraph, { FlowGraph } from './modules/flowGraph';
import analytics, { AnalyticsStoreState } from './modules/analytics';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const debug = (import.meta as any)?.env?.DEV;

export const store = createStore({
  modules: {
    ui,
    links,
    flowGraph,
    analytics,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});

export interface State {
  ui: UIState;
  links: Links;
  flowGraph: FlowGraph;
  analytics: AnalyticsStoreState;
}
