import { NodePage, Next, Value } from '../IO/db';
import * as R from 'ramda';
import { store } from '@/coaf/store/index';

type stringNumber = string | number | boolean;
const comparison = (comparison: string) => (a: stringNumber[], b: stringNumber[]) => {
  switch (comparison) {
    case 'equals':
      return equals(a, b);
    case 'contains':
      return R.isEmpty(R.without(b)(a));
    case 'less_than':
      return a < b;
    case 'greater_than':
      return a > b;
    case 'true':
      return true;
    default:
      setError(`Comparison not implemented: ${comparison}`);
      throw new Error(`Comparison not implemented: ${comparison}`);
  }
};

function setError(text: string) {
  const res = {
    errorMessage: [text],
    errorTicket: undefined,
    errorTrace: undefined,
    errorType: text,
    errorUserMessage: text,
  };
  store.commit('ui/SET_MODAL_STATE', { isVisible: true, modalType: 'errorModal', dynamicText: res });
}

export const matchNextNodeByValue =
  (nodes: Next[]) =>
  (values: Value[]): Next | undefined => {
    if (values.length === 0 && nodes.some((x) => x?.condition?.comparison === 'true')) {
      return nodes.find((x) => x.condition.comparison === 'true');
    }
    const groupedValues = [...groupByComponentId(values).values()];
    const foundNode = nodes.find((x) => {
      const wrappedValue =
        typeof x.condition.value === 'string' || typeof x.condition.value === 'number'
          ? [x.condition.value]
          : x.condition.value;
      const val = groupedValues.some((value) => comparison(x.condition.comparison)(wrappedValue, value));
      return val;
    });
    return foundNode;
  };

export const findNextNode = (page: NodePage, values: Value[]): Next | null => {
  const matched = matchNextNodeByValue(page.next)(values);
  if (matched === undefined) {
    return null;
    // throw new Error(`Could not find next node: ${page.next}, ${value}`);
  }
  return matched;
};

function groupByComponentId(arr: Value[]) {
  return arr.reduce((acc, cur: Value) => {
    const valFromMap: stringNumber[] = acc.has(cur.componentId) ? acc.get(cur.componentId) : [];
    const valToPutIn = [...valFromMap, cur.value];
    return acc.set(cur.componentId, valToPutIn);
  }, new Map());
}

function equals<T>(a: T[], b: T[]): boolean {
  if (typeof a !== 'object') {
    a = [a];
  }
  return R.equals(a)(b);
}
