import { setToken } from '@/lib/helpers';
import { createFlowService, FlowService } from '@coaf-library/flow/flow';
import { Flow, NodePage, Value } from '@coaf-library/IO/db';
import { flowRepository } from '@coaf-library/IO/flow-web-repository';
import { Router } from 'vue-router';
import { Commit, Dispatch } from 'vuex';

export interface FlowGraph {
  flow?: Flow;
  appName?: unknown;
  flowName?: unknown;
  hasGraph: boolean;
  graph?: FlowService;
  metadata: Metadata;
}

export interface Metadata {
  appName: string;
  appVersion: number;
  companyId: string;
  companyName: string;
  flowStartup: boolean;
  identity: string;
  isReadOnly: boolean;
  pdf: string | null;
  language: '' | 'en' | 'da';
  serviceMessage: null | string;
}

const state = (): FlowGraph => ({
  hasGraph: false,
  graph: undefined,
  metadata: {
    appName: '',
    appVersion: 0,
    companyName: '',
    flowStartup: false,
    isReadOnly: false,
    pdf: '',
    identity: '',
    companyId: '',
    language: '',
    serviceMessage: null,
  },
});

const getters = {
  GET_GRAPH: (state: FlowGraph): FlowService | undefined => {
    return state.graph;
  },
  GET_NODE:
    (state: FlowGraph) =>
    (ID: string): NodePage | undefined => {
      return state.graph?.getNode(ID);
    },
  GET_IS_GRAPH_DEFINED: (state: FlowGraph): boolean => {
    return state.hasGraph;
  },
  GET_METADATA(state: FlowGraph): Metadata {
    return state.metadata;
  },
};

const mutations = {
  SET_GRAPH(state: FlowGraph, flow: FlowService): void {
    if (flow !== undefined) {
      state.hasGraph = true;
    } else {
      state.hasGraph = false;
    }
    state.graph = flow;
  },

  SET_NODE_VALUE(state: FlowGraph, { ID, values }: { ID: string; values: Value[] }): void {
    if (state.graph === undefined || state.hasGraph === false) {
      return;
    }
    state.graph.setNodeValue(ID, values);
  },

  SET_GRAPH_STATE(state: FlowGraph, ID: string): void {
    if (state.graph === undefined || state.hasGraph === false) {
      return;
    }
    state.graph.transitionStateFromNode(ID);
  },

  SET_METADATA(state: FlowGraph, metadata: Metadata): void {
    state.metadata = metadata;
  },
  POP_STATE(state: FlowGraph): void {
    if (state.graph === undefined || state.hasGraph === false) {
      return;
    }
    state.graph.popNode();
  },
};

const actions = {
  async createGraph(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    { appID, flowID, nodeID, router }: { appID: string; flowID: string; nodeID: string; router: Router }
  ): Promise<unknown> {
    // const router = useRouter();
    const res = await flowRepository.startSubmissionCommand({ appID, flowID, nodeID }, router.currentRoute.value.query);
    const flowService = createFlowService(res.flow, res.appName);
    commit('SET_GRAPH', flowService);
    commit('SET_METADATA', res.metadata);
    setToken(res.token);
    dispatch('analytics/postIdentity', { ID: res.metadata?.identity, values: res.metadata }, { root: true });

    // When a flow is the first we should reset a users path to the beginning
    // on new requests.
    if (res.metadata?.flowStartup) {
      router.replace(`${res.flow.startNodeId}`);
    }
    // useErrorHandler(app, res.metadata)
    return { ok: true, data: { metadata: res.metadata } };
  },

  transitionState({ commit }: { commit: Commit }, { nodeID }: { nodeID: string }): boolean {
    commit('SET_GRAPH_STATE', nodeID);
    return true;
  },

  postValue({ commit }: { commit: Commit }, { ID, values }: { ID: string; values: Value[] }): boolean {
    commit('SET_NODE_VALUE', { ID, values });
    return true;
  },

  removeValuesFromNode({ commit, getters }: { commit: Commit; getters: any }, { ID }: { ID: string }): Value[][] {
    const node = getters.GET_NODE(ID) as NodePage;

    commit('SET_NODE_VALUE', { ID, values: [] });
    commit('POP_STATE');

    return node.components.map((x) => x.values);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
