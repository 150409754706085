import * as Sentry from '@sentry/browser';
import { ExtraErrorData, Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { keyedString } from './helperTypes';
import {} from 'vue';
import posthog, { PostHog } from 'posthog-js';

export interface AnalyticsService {
  init: (uid: string, options: keyedString) => void;
  sendEvent: (name: string, data?: keyedString) => void;
  setUserProperty: (name: string, data?: keyedString) => void;
  identify: (uid: string, data?: keyedString) => void;
  reset: (resetDeviceId?: boolean) => void;
}
export class AnalyticsLogger {
  constructor(private loggerService: PostHog) {}
  isInitialized = false;
  init(apiKey: string, options: posthog.Config): void {
    this.loggerService.init(apiKey, options);
    this.loggerService.opt_in_capturing();
    this.isInitialized = true;
  }

  optInToCapture() {
    if (this.isInitialized !== true || this.loggerService.has_opted_in_capturing()) {
      return;
    }
    this.loggerService.opt_in_capturing({
      persistence_type: 'localStorage',
      clear_persistence: false,
      cookie_expiration: 365,
      cookie_prefix: '',
      cross_subdomain_cookie: false,
      secure_cookie: true,
    });
  }

  optOutOfCapture() {
    if (this.isInitialized !== true || this.loggerService.has_opted_out_capturing()) {
      return;
    }
    this.loggerService.opt_out_capturing();
  }

  registerGlobalProperty(data: keyedString) {
    if (this.isInitialized !== true || this.loggerService.has_opted_out_capturing()) {
      return;
    }
    this.loggerService.register(data);
  }

  identify(userID: string, properties: keyedString) {
    if (this.isInitialized !== true || this.loggerService.has_opted_out_capturing()) {
      return;
    }
    this.loggerService.identify(userID, properties);
  }

  setUserProperty(data: keyedString) {
    if (this.isInitialized !== true || this.loggerService.has_opted_out_capturing()) {
      return;
    }
    this.loggerService.people.set(data);
  }

  sendEvent(name: string, data?: keyedString) {
    if (this.isInitialized !== true || this.loggerService.has_opted_out_capturing()) {
      return;
    }
    this.loggerService.capture(name, data);
  }

  reset() {
    if (this.isInitialized !== true) {
      return;
    }
    this.loggerService.reset();
  }

  stopCapture() {
    if (this.isInitialized !== true || this.loggerService.has_opted_out_capturing()) {
      return;
    }
    this.loggerService.opt_out_capturing();
  }

  isFeatureEnabled(name: string) {
    this.loggerService.isFeatureEnabled(name, { send_event: true });
  }
}

export const analyticsLogger = new AnalyticsLogger(posthog);
