export function toQueryObject(theString: string) {
  const theArray = theString.split('&');
  const theQuery = theArray
    .map((x) => x.split('='))
    .reduce((prev, cur) => {
      prev[cur[0]] = cur[1];
      return prev;
    }, {} as Record<string, string>);
  return theQuery;
}

export function getToken(): 'null' | string {
  const hasStorageToken = window.sessionStorage.getItem('token') ?? window.localStorage.getItem('token');
  return hasStorageToken ? hasStorageToken : 'null';
}

export function setToken(value: string): void {
  window.sessionStorage.setItem('token', value);
  window.localStorage.setItem('token', value);
}
