<template>
  <div id="translationOptions" class="flex flex-col justify-center z-[11]" :style="{ color: txtColor }">
    <div
      class="lg:py-2 px-2 lg:px-5 w-12 lg:w-32 flex justify-center relative cursor-pointer mb-1 lg:mb-0"
      @click="languageIsExpanded = !languageIsExpanded"
    >
      <img class="inline-flex h-[1.2rem] w-[1.8rem] object-cover" :src="selectedLang.url" />
      <span class="ml-2 hidden lg:inline-block leading-none my-auto">{{ selectedLang.label }}</span>
    </div>
    <FadeTransition :duration="100" mode="in-out">
      <div v-show="languageIsExpanded" class="dropdownBg cursor-pointer bg-[#e3e3e3] hover:bg-[#d4d4d4] rounded-md">
        <template v-for="lang of languages" :key="lang.locale">
          <div
            v-if="lang.locale !== selectedLang.locale"
            @click="handleLangChange(lang)"
            class="flex justify-center items-center py-2"
          >
            <img class="inline-block h-[1.2rem] w-[1.8rem] object-cover" :src="lang.url" />
            <span class="ml-2 hidden lg:inline-block leading-none my-auto">{{ lang.label }}</span>
          </div>
        </template>
      </div>
    </FadeTransition>
  </div>
</template>

<script lang="ts">
interface Lang {
  label: string;
  locale: string;
  url: string;
}
</script>

<script lang="ts" setup>
import { ref, defineProps, defineEmits, ComputedRef, computed, withDefaults, onUpdated } from 'vue';

const emit = defineEmits(['selectedLanguage']);

const props = withDefaults(defineProps<{ txtColor?: string; selectedLang: Lang; languages: Lang[] }>(), {
  txtColor: 'color: rgb(0, 0, 0)',
});

const languageIsExpanded = ref(false);

const selectedLang: ComputedRef<Lang> = computed(() => props.selectedLang);

onUpdated(() => {
  document.documentElement.setAttribute('lang', props.selectedLang.locale.toLowerCase());
});

function handleLangChange(lang: Lang) {
  emit('selectedLanguage', lang);
  languageIsExpanded.value = false;

  const locale = lang.locale.toLowerCase();
  const url = new URL(window.location.href);
  url.searchParams.set('lang', locale);

  window.location.href = url.href;
}
</script>
