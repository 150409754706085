import { setToken } from '@/lib/helpers';
import { HubConnection, HubConnectionBuilder, ISubscription, JsonHubProtocol, LogLevel } from '@microsoft/signalr';
import { TableResponse } from './db';
import { Store } from 'vuex';

export interface responseError {
  errorSystemMessage: string;
  errorTicket: string;
  errorUserMessage: string;
}

export interface ResponseData {
  token: string;
  success: boolean;
  errorTicket: null | string;
  errorUserMessage: null | string;
  errorSystemMessage: null | string;
  data: null | DataProps;
}

export interface DataProps {
  returnUrl?: string;
  data?: TableResponse[];
}

export class SignalR {
  connection: HubConnection;
  retryArray = [...new Array(10)].map((x, i) => i * i * 100 + Math.random() * (i * 1000));
  store?: Store<any>;
  constructor(url: string, private jwtToken: string, store?: Store<any>) {
    this.connection = new HubConnectionBuilder()
      .withAutomaticReconnect(this.retryArray)
      .withHubProtocol(new JsonHubProtocol())
      .configureLogging(LogLevel.Debug)
      .withUrl(url)
      .build();
  }

  set jwt(jwtToken: string) {
    this.jwtToken = jwtToken;
    setToken(jwtToken);
  }

  async get(methodName: string): Promise<ISubscription<any>> {
    return this.connection.stream(methodName).subscribe({
      complete: () => console.log('done'),
      next: () => console.log('next'),
      // error: (err) => console.error('error'),
      error: (err) => {
        this.store?.commit('ui/SET_MODAL_STATE', { isVisible: true, modalType: 'errorModal', dynamicText: err });
      },
    });
  }

  async post(methodName: string, data: Record<string, unknown>): Promise<void> {
    return this.connection.send(methodName, JSON.stringify({ ...data, token: this.jwtToken }));
  }

  async start(store: Store<any>): Promise<void> {
    try {
      const res = await this.connection.start();
      return res;
    } catch (error) {
      store?.commit('ui/SET_MODAL_STATE', {
        isVisible: true,
        modalType: 'errorModal',
        dynamicText: { errorUserMessage: error + ` SignalR ${this.connection?.connectionState}` },
      });
    }
  }

  unsubscribe(methodName: string): void {
    this.connection.off(methodName);
  }

  /**
   * Creates a subscription listening to all events send on the `methodName`
   * @param methodName name of method to listen for events on
   */
  subscribe(methodName: string) {
    return (fn: (...args: ResponseData[]) => void): unknown => this.connection.on(methodName, fn);
  }
}

// const api = new SignalR("/api/coaf/hub")

// api.get("someUrl").then(result => console.log(result))
// api.post("someUrl", { hello: "world" }).then(result => console.log(result))

// const subscription = api.subscribe("rxjs")
// subscription(data => console.log("sub", data))
