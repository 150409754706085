import { createApp } from 'vue';
import App from './coaf/App.vue';
import { Modal, FadeTransition, NextButton } from '@pensure/ui-components';
import { createI18n } from 'vue-i18n';
import { store } from './coaf/store';
import '../index.scss';

import '../node_modules/@pensure/ui-components/dist/style.css';

import messages from '../i18n.json';

import router from './coaf/router';
import { logger, ErrorLogger } from '../coaf-library/helpers/ErrorService';

import { analyticsLogger } from '@coaf-library/helpers/AnalyticsService';

import { checkIfOldBrowsers } from '@coaf-library/helpers/helperFunctions';
import injectScripts from '../src/wizard-flow/helpers/injectScriptTags';
import { keyboard } from './controller/keyboard';
import smoothscroll from 'smoothscroll-polyfill';

const useKeyboardTrap = () => {
  document.onkeydown = keyboard.trapKeyboard;
};

useKeyboardTrap();

function useAnalytics(metadata) {
  const isDev = import.meta.env.DEV;
  if (isDev) {
    return;
  }
  const initOptions = {
    api_host: import.meta.env.VITE_ANALYTICS_HOST,
    opt_out_capturing_by_default: true,
    rageclick: true,
    cross_subdomain_cookie: false,
    secure_cookie: true,
    loaded: function (posthog) {
      if (metadata?.identity) {
        posthog.identify(metadata.identity);
      }
    },
  };
  const apiKey = import.meta.env.VITE_ANALYTICS_API_KEY;
  analyticsLogger.init(apiKey, initOptions);
}

const useErrorHandler =
  (app) =>
  ({ metadata }) => {
    const errorLogger = new ErrorLogger(logger(app));

    errorLogger.init({
      // id: import.meta.env.VITE_ERROR_REPORTING_KEY,
      release: `${import.meta.env.VITE_ERROR_REPORTING_PROJECT}@${metadata.appVersion}`,
      dsn: import.meta.env.VITE_ERROR_REPORTING_DSN,
      overrideConsole: import.meta.env.env.VITE_ERROR_REPORTING_OVERRIDE_CONSOLE === 'true',
      environment: import.meta.env.MODE,
    });
    errorLogger.identify(metadata.identity, {
      latestRelease: metadata.appVersion,
      appName: metadata.appName,
      companyId: metadata.companyId,
    });
  };

async function setupVue() {
  const app = createApp(App);

  const providedErrorHandler = useErrorHandler(app);
  app.provide('useErrorHandler', providedErrorHandler);

  const i18n = createI18n({
    locale: 'dk',
    fallbackLocale: 'en',
    messages,
  });

  // await redirectToStartNode(appID, flowService.name, flowService.initialState.nodeId);

  // Vue setup
  // app.provide('globalError', globalError)
  // app.provide('flowService', flowService);
  // app.provide('apiSocket', apiSocket);
  app.component('modal', Modal);
  app.component('FadeTransition', FadeTransition);
  app.component('NextButton', NextButton);
  // app.config.errorHandler = ErrorHandler
  app.use(i18n);
  app.use(store);
  app.use(router);

  app.mount('#app');

  // Error logger
  useAnalytics();
}

// manualy inject polyfils if browser is <= safari 12 and <= edge 16
if (!getComputedStyle(document.body).scrollBehavior) {
  smoothscroll.polyfill();
}
if (checkIfOldBrowsers()) {
  const url =
    'https://polyfill.io/v3/polyfill.min.js?features=Array.from,Array.isArray,Array.prototype.every,Array.prototype.filter,Array.prototype.find,Array.prototype.findIndex,Array.prototype.flat,Array.prototype.forEach,Array.prototype.includes,Array.prototype.indexOf,Array.prototype.keys,Array.prototype.lastIndexOf,Array.prototype.map,Array.prototype.reduce,Array.prototype.some,Array.prototype.sort,Array.prototype.values,ArrayBuffer,atob,Blob,console,CustomEvent,Date.now,document,fetch,getComputedStyle,Element.prototype.scroll,JSON,Map,Math.sign,Object.assign,Object.create,Object.defineProperties,Object.defineProperty,Object.getOwnPropertyDescriptor,Object.getOwnPropertyNames,Object.getOwnPropertySymbols,Object.keys,Object.setPrototypeOf,Object.values,Promise,Set,Element.prototype.animate,Element.prototype.animate%2CWebAnimations,String.prototype.endsWith,String.prototype.includes,String.prototype.startsWith,String.prototype.trim,Symbol,Symbol.iterator,Uint8Array,URL,WeakMap,WeakSet,XMLHttpRequest';
  fetch(url)
    .then((x) => x.text())
    .then((resp) => {
      injectScripts(document.getElementsByTagName('body')[0], [{ text: resp }]);
      setupVue();
    });
} else {
  setupVue();
}
