import { RouteRecordRaw } from 'vue-router';
import FlowWrapper from '../views/FlowWrapper.vue';
import App from '../App.vue';

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: App,
  },
  {
    path: '/:application/:flowID?/:pageID?',
    name: 'WizardFlow',
    component: FlowWrapper,
  },
];
