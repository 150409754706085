export interface Links {
  links: Record<string, string>;
}

const state = (): Links => ({
  links: {},
});

const getters = {
  GET_LINKS: (state: any) => {
    return state.links;
  },
  // GET_MODAL_STATE(state: any) {
  //   return state.links;
  // },
};

const mutations = {
  SET_LINKS(state: any, newPropertyValue: Record<string, string>): void {
    state.links = Object.assign({}, state.links, newPropertyValue);
  },
  // SET_MODAL_STATE(state: any, newPropertyValue: Record<string, unknown>): void {
  //   state.modal.isVisible = newPropertyValue;
  // },
};

// const actions = {
//   toggleModal({ commit, state }: any) {
//     commit('SET_MODAL_STATE', !state.modal.isVisible);
//   },
//   hideModal({ commit, state }: any) {
//     commit('SET_MODAL_STATE', false);
//   },
//   showModal({ commit, state }: any) {
//     commit('SET_MODAL_STATE', true);
//   },
// };

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  // actions,
};
