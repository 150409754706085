import { Value } from '@coaf-library/IO/db';
import { analyticsLogger } from '@coaf-library/helpers/AnalyticsService';
import { keyedString } from '@coaf-library/helpers/helperTypes';

export interface AnalyticsStoreState {
  identity: string | undefined;
}
type ASS = AnalyticsStoreState;
const state = (): ASS => ({
  identity: undefined,
});

const getters = {
  GET_IDENTITY: (state: ASS) => {
    return state.identity;
  },
};

const mutations = {
  SET_ANALYTICS(state: ASS, analytics: Partial<ASS>): void {
    state = { ...state, ...analytics };
  },

  SET_IDENTITY(state: ASS, ID: string): void {
    state.identity = ID;
  },
};

interface IdentifyModel {
  ID: string;
  values: keyedString;
}
const actions = {
  async postIdentity({ commit, state }: any, { ID, values }: IdentifyModel) {
    analyticsLogger.identify(ID, values);
    commit('SET_IDENTITY', ID);
    return true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
