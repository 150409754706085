/* eslint-disable @typescript-eslint/no-explicit-any */
export function setColorBrightness(state: string, color2: string): string | undefined {
  const color = color2.match(/[\d]+/g);
  if (!color || !state) return;
  const increment = 50;
  let r = parseInt(color[0], 10);
  let g = parseInt(color[1], 10);
  let b = parseInt(color[2], 10);
  if (state === 'lighten') {
    r = r + increment;
    g = g + increment;
    b = b + increment;
    return `${r < 255 ? r : 255}, ${g < 255 ? g : 255}, ${b < 255 ? b : 255}`;
  } else if (state === 'darken') {
    r = r - increment;
    g = g - increment;
    b = b - increment;
    return `${r > 1 ? r : 1}, ${g > 1 ? g : 1}, ${b > 1 ? b : 1}`;
  }
}

export function setColor(color2?: string): string | undefined {
  const bgColor = color2 ? color2 : getComputedStyle(document.documentElement).getPropertyValue('--color-primary');
  const color = bgColor.match(/[\d]+/g);

  if (!color) return;
  const r = parseInt(color[0], 10);
  const g = parseInt(color[1], 10);
  const b = parseInt(color[2], 10);
  if ((r * 299 + g * 587 + b * 114) / 1000 > 160) {
    return 'rgb(40, 40, 40)';
  }
  return 'rgb(250, 250, 250)';
}

export function checkIfOldBrowsers(): boolean {
  // if < safari 12  or   if < edge 16
  return !window.visualViewport || !Array.prototype.flat;
}

export function isFacebookInAppBrowser(): boolean {
  const strings = ['FB_IAB', 'FBAN', 'FBAV', 'wv'];
  return strings.some((el) => navigator.userAgent.includes(el));
}

export function openInDefaultBrowser(target: string, browserScheme: string): void {
  const ifc = document.createElement('div');
  ifc.innerHTML = `<iframe src='${browserScheme}${target}' style='width:100;height:100;border:0; border:none;visibility: hidden;'></iframe>`;
  document.body.appendChild(ifc);
}

export function checkIfAndroid(): boolean {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('android') > -1;
}

export function removeTokenAndRedirect(url: string): void {
  window.sessionStorage?.removeItem('token');
  window.localStorage?.removeItem('token');
  window.location.href = url;
}

export function generateRandomKey(length = 8): string {
  return Math.floor(Math.random() * Math.pow(10, length)).toString();
}

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export function getTextWidth(text: string, font: string): number {
  // re-use canvas object for better performance

  const canvas = (getTextWidth as any).canvas || ((getTextWidth as any).canvas = document.createElement('canvas'));
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  ctx.font = font;
  return ctx.measureText(text).width;
}

export function getTransitionDuration(el: HTMLElement): number {
  return window
    .getComputedStyle(el)
    .transitionDuration.replace(/[s|ms|]+/g, '')
    .split(',')
    .map((str) => parseFloat(str))
    .reduce((prevVal, currVal) => prevVal + currVal);
}

export function isObject(value: unknown): boolean {
  return Object.prototype.toString.call(value) === '[object Object]';
}
