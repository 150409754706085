import { SignalR } from '../coaf-library/IO/signalRWrapper';
import { useStore } from 'vuex';

export const bootstrapApiSocket = async (url: string, token: string): Promise<SignalR> => {
  const dev = window.location.host.startsWith('localhost');
  const pathSep = window.location.origin.startsWith('/') ? '' : '/';
  url = dev ? 'http://localhost:5000/api/coaf/hub' : window.location.origin + pathSep + 'api/coaf/hub';
  const store = useStore();

  const apiSocket = new SignalR(url, token, store);
  await apiSocket.start(store);

  return apiSocket;
};
